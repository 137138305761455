import "alpinejs";
// core version + navigation, pagination modules:
import Swiper, { Navigation, Lazy, EffectFade, Autoplay } from "swiper";

// configure Swiper to use modules
Swiper.use([Navigation, Lazy, EffectFade, Autoplay]);

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

function init() {
  // const elem = document.querySelector("#myDiv");
  // if(elem) {
  //     const rect = elem.getBoundingClientRect();
  //     console.log(`height: ${rect.height}`);
  // }

  // init Swiper:
  const swiper = new Swiper(".swiper-container", {
    fadeEffect: {
      crossFade: true,
    },
    effect: "fade",
    loop: true,
    // autoHeight: true,
    navigation: {
      nextEl: ".arrow-next",
      prevEl: ".arrow-prev",
    },
  });
  const swiperHome = new Swiper("#gallery-home", {
    // fadeEffect: {
    //     crossFade: true
    // },
    autoplay: {
      delay: 2500,
      disableOnInteraction: false,
    },
    effect: "fade",
    lazy: true,
    loop: true,
  });
  var headerList = document.getElementsByClassName("js-header__nav")[0];
  if (headerList) {
    //   window.addEventListener('load', function() {
    //     var offsetHeight = headerList.offsetHeight;
    //     bodyHome.style['padding-top'] = offsetHeight + 'px';
    //   })
    //   window.addEventListener( 'scroll', function() {
    //     var scrollDistance = document.documentElement.scrollTop;
    //     if ( scrollDistance > 0) {
    //       var mass = Math.max(0.5, 1 - 0.0014 * scrollDistance);
    //       headerList.style.cssText += 'transform: scale(' + mass + ')';
    //     }
    //   })
    ScrollTrigger.matchMedia({
      // desktop
      "(max-width: 1023px)": function () {
        // setup animations and ScrollTriggers for screens 800px wide or greater (desktop) here...
        // These ScrollTriggers will be reverted/killed when the media query doesn't match anymore.
        gsap.to(headerList, {
          scrollTrigger: {
            trigger: headerList,
            start: "bottom 80%",
            end: "bottom 60%",
            scrub: true,
            //   markers: true,
          },
          scale: 0.666666666666666,
          transformOrigin: "top left",
        });
        gsap.to("#gallery-home", {
          scrollTrigger: {
            trigger: "#gallery-home",
            start: "top 60%",
            end: "top 30%",
            scrub: true,
            //   markers: true
          },
          opacity: 1,
        });
      },
      // desktop
      "(min-width: 1024px)": function () {
        // setup animations and ScrollTriggers for screens 800px wide or greater (desktop) here...
        // These ScrollTriggers will be reverted/killed when the media query doesn't match anymore.
        gsap.to(headerList, {
          scrollTrigger: {
            trigger: headerList,
            start: "bottom 95%",
            end: "top 100px",
            scrub: true,
            // markers: true,
          },
          scale: 0.5,
          transformOrigin: "top left",
        });
        gsap.to("#gallery-home", {
          scrollTrigger: {
            trigger: "#gallery-home",
            start: "top 70%",
            end: "top 30%",
            scrub: true,
            // markers: true
          },
          opacity: 1,
          // y: '-10%',
        });
      },
    });
  }
}

import Swup from "swup";
import SwupPreloadPlugin from "@swup/preload-plugin";
const options = {
  linkSelector:
    'a[href^="' +
    window.location.origin +
    '"]:not([data-no-swup]), a[href^="/"]:not([data-no-swup]), a[href^="#"]:not([data-no-swup])',
  containers: ["#swup", "#nav"],
  plugins: [new SwupPreloadPlugin()],
};
const swup = new Swup(options);

// run once
init();

// this event runs for every page view after initial load
swup.on("contentReplaced", init);
